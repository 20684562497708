import React from "react"
import styled from "styled-components"

const SingleRadioButtonWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin: ${({isSmallMargin}) => isSmallMargin ? "0 20px 10px 0" : "0 20px 50px 0"};
	@media(min-width: 414px) {
		max-width: 353px;
	}
	@media(min-width: 768px) {
		justify-content: flex-start;
	}
`;

const SingleRadioButton = styled.div`
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	width: 100%;
	height: 50px;
	top: 3px;
	border-radius: 6px;
	border: 1px solid ${({theme}) => theme.colors.formBlue};
	background-color: ${({theme}) => theme.colors.formTransparent};
	position: relative;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	font-weight: ${({theme}) => theme.fontWeight.bold};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;	
	&:hover {
		border: 1px solid ${({theme}) => theme.colors.formFontDarkBlue};
	}
	@media(min-width: 768px) {
        width: 170px;
	  	height: 50px;
	}
	@media(min-width: 1280px) {
		margin-bottom: 0;
	}
	&.active {
		border: 2px solid ${({ theme }) => theme.colors.formAlwisOrange};
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({theme}) => theme.colors.formAlwisOrangeLightOrange}
	}
`;

const ErrorValidation = styled.p`
	font-size: 16px;
	display:none;
	color: ${({theme}) => theme.colors.formAlwisOrange};
	position: absolute;
	transform: translateY(53px);
	text-align: center;
&:nth-of-type(1){
	display:block;
	/* &:nth-last-child(2){
		display:none;
	} */
}`


const ErrorNone = styled.span`
visibility: hidden;
position:absolute;
`;

const SingleRadioButtonTwoOptionsReduxForm = ({input, meta: {error, touched}, amountName, id, activeId, onClick, defaultId, isMaxWidthBigger, isSmallMargin, htmlId }) => {
	const tempId = activeId === true ? 1 : activeId === false ? 2 : 0;
	return (
		<>
		<SingleRadioButtonWrapper id={htmlId} isSmallMargin={isSmallMargin} isMaxWidthBigger={isMaxWidthBigger} key={id}
		                          // onClick={() => onClick(id, amountName)}
		                          onClick={() => onClick(id)}
		>
			<SingleRadioButton {...input} className={((tempId || defaultId) === id) ? "active" : ""}>{amountName}</SingleRadioButton>

		</SingleRadioButtonWrapper>
		{touched &&((error && <ErrorValidation> <ErrorNone> {error}</ErrorNone>pole wymagane</ErrorValidation>))}
		</>
	)
};

export default SingleRadioButtonTwoOptionsReduxForm;
