import React from "react"
import styled from "styled-components"
import PenIcon from "../../images/OtherAssets/pencil-orange.svg"
import { CategoryHeader } from "../common/CategoryHeader"
import MaskedInput from "react-text-mask";
import {ErrorMessageText, HeaderAndQuestionMarkWrapper} from "../sharedComponents/SharedComponents";
import CircleWithQuestionMark from "../tooltip/CircleWithQuestionMark";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: ${({isNotMarginTop}) => isNotMarginTop ? "0px" : "50px"};
  width: 100%;
  margin-bottom: ${({twoInRow, isRequired}) => (twoInRow && !isRequired) ? "30px" : "0"};
  @media(min-width: 414px) {
  	width: 353px;
  }
  @media(min-width: 536px) {
  	width: 476px;
  }
  @media(min-width: 768px) {
  	width: 520px;
  	margin-right: ${({twoInRow}) => twoInRow ? "45px" : "0"};
  	margin-bottom: ${({twoInRow, isRequired}) => (twoInRow && !isRequired) ? "60px" : "0"};
  }
  @media(min-width: 1024px) {
  	width: ${({twoInRow}) => twoInRow ? "250px" : "520px"};
  	margin-bottom: ${({twoInRow, isRequired}) => (twoInRow && !isRequired) ? "50px" : "0"};
  }
  @media(min-width: 1920px) {
  	width: ${({isEditable}) => isEditable ? "250px" : "550px"};
  	margin-right: 50px;
  }
    &:before {
		content: '';
		position: absolute;
		z-index: 10;
		cursor: pointer;
		right: 25px;
		top: 48px;
		background-image: url(${PenIcon});
		background-repeat: no-repeat;
		background-size: contain;
		width: 20px;
		height: 20px;
	 	display: ${({isEditable, isPenClicked}) => (isEditable && !isPenClicked) ? "block" : "none"};
	}
  input.masked-input {
    border: 1px solid ${({theme}) => theme.colors.formBlue};
	border-radius: 10px;
	background-color: ${({theme}) => theme.colors.formWhite};
	outline: none;
	display: block;
	padding: 14px 24px;
	margin-top: 10px;
	position: relative;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-size: 16px;
	line-height: 20px;
	caret-color: ${({theme}) => theme.colors.formAlwisOrange};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	cursor: pointer;
	box-shadow: none;
	@media(min-width: 768px) {
		padding: 19px 24px;
	} 
	&:hover {
		border: 1px solid  ${({theme}) => theme.colors.formFontDarkBlue};
	}
	&::-webkit-inner-spin-button, 
	&::-webkit-outer-spin-button { 
	  -webkit-appearance: none; 
	  margin: 0; 
	}
  }
`;

const StyledInput = styled.input`
	border: 1px solid ${({theme}) => theme.colors.formBlue};
	border-radius: 10px;
	background-color: ${({theme, isEditable}) => isEditable ? theme.colors.formInputGreyBackground : theme.colors.formWhite};
	outline: none;
	display: block;
	padding: 14px 24px;
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	margin-top: ${({isValidationError}) => isValidationError ? "4px" : "5px"};
	width: 100%;
	position: relative;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-size: 16px;
	line-height: 20px;
	caret-color: ${({theme}) => theme.colors.formAlwisOrange};
	cursor: pointer;
	box-shadow: none;
	@media(min-width: 768px) {
		padding: 19px 24px;
	}
	&::placeholder {
		font-size: 16px;
		line-height: 20px;
		color: ${({theme}) => theme.colors.formLightGrey};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media(min-width: 1280px) {
			font-size: 16px;
		}
	}
	@media(min-width: 1280px) {
		margin-top: 5px;
	}
	@media(min-width: 1920px) {
		padding: 18px 24px;
		margin-top: ${({isValidationError}) => isValidationError ? "-1px" : "0"};
		margin-bottom: ${({isValidationError}) => isValidationError ? "0" : "-1px"};
	}
	&:hover {
		border: 1px solid  ${({theme}) => theme.colors.formFontDarkBlue};
	}
	&:focus {
		border: ${({ theme }) => "1px solid" + theme.colors.formBlue};
		background-color: ${({theme}) => theme.colors.formWhite};
	}
	&::-webkit-inner-spin-button, 
	&::-webkit-outer-spin-button { 
	  -webkit-appearance: none; 
	  margin: 0; 
	}
	&[type=number] {
		-moz-appearance: textfield;
	}
	&:before {
		content: '';
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		background-color: blue;
		width: 10px;
		height: 40px;
	}
	&.active {
		border: ${({ theme }) => "1px solid" + theme.colors.formAlwisOrange};	
	}
	&.wrong-email {
		border: ${({ theme }) => "3px solid" + theme.colors.formBlack};
		background-color: ${({ theme }) => theme.colors.formLightGrey}
	}
  
`;

const UnitText = styled.p`
	position: absolute;
	right: 20px;
	top: calc(50% + 5px);
    color: ${({theme}) => theme.colors.formFontDarkBlue};
    font-size: 16px;
    line-height: 20px;
    font-weight: ${({theme}) => theme.fontWeight.bold};
    @media(min-width: 1280px) {
    	top: calc(50% + 7px);
    }
    @media(min-width: 1920px) {
    	top: calc(50% + 5px);
    }
`
const zipCodeMask = [
	/[0-9]/,
	/[0-9]/,
	"-",
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
];

const ErrorNone = styled.span`
visibility: hidden;
position:absolute;
`;

const SingleTextInput = ({ input, label, type, meta: { touched, error }, editableInputHeader, placeholder, onChange, value, name, isRequired, isZipCodeMask, unitName, twoInRow, isNotMarginTop,
	                           isEditable, handlePenClick, isPenClicked, isValidationError, isCity, onClick, showCircle, isWindowVisible, tooltipHeader, tooltipDescription, isFirstOnPage }) => {

	return (
		<SelectWrapper twoInRow={twoInRow} isNotMarginTop={isNotMarginTop} isEditable={isEditable} isPenClicked={isPenClicked} isRequired={isRequired}>
			{editableInputHeader &&	<HeaderAndQuestionMarkWrapper><CategoryHeader>{editableInputHeader}</CategoryHeader><CircleWithQuestionMark isFirstOnPage={isFirstOnPage} showCircle={showCircle} onClick={onClick} isWindowVisible={isWindowVisible} tooltipHeader={tooltipHeader} tooltipDescription={tooltipDescription}/>
			</HeaderAndQuestionMarkWrapper>}
			{!isZipCodeMask && <> <StyledInput
				{...input}
				placeholder={placeholder}
				type={type}
				name={name}
				error={error}
				touched={touched}

				// isValidationError={isValidationError}
				// disabled={isPenClicked}
				// disabled={isCity}
				// value={value}
				// required={isRequired}
				// onChange={onChange}
				// onClick={handlePenClick}
				// isEditable={isEditable}
				// isPenClicked={isPenClicked}
			/> <UnitText>{(unitName) ? unitName : ""}</UnitText>  </>}
			{isZipCodeMask && <MaskedInput
				{...input}
				placeholder={placeholder}
				type={type}
				name={name}
				error={error}
				touched={touched}
				mask={zipCodeMask}
				id="zipCode"
				// value={value}
				// isValidationError={isValidationError}
				className="masked-input"
				// onChange={onChange}
			/> }
			{/* {touched && error && <ErrorMessageText>{error}</ErrorMessageText>} */}
			{touched && ((error && <ErrorMessageText> <ErrorNone> {error}</ErrorNone>pole wymagane</ErrorMessageText>))}
			
		</SelectWrapper>
	)
}

export default SingleTextInput
